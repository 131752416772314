const de = {
  notFoundTitle: "Nicht gefunden",
  notFoundDescription: "Die Seite, die du aufrufen wolltest, konnte nicht gefunden werden.",
  goHome: "Zur Startseite",
}

const en: typeof de = {
  notFoundTitle: "Not found",
  notFoundDescription: "The page you tried to access could not be found.",
  goHome: "Go to frontpage",
}

export const notFoundText = { de, en }
