"use client"

import { InteractiveLink } from "src/components/common/InteractiveLink"
import { notFoundText } from "src/components/common/notFoundText"
import { routes } from "src/config/routes"
import { useIsClient } from "src/hooks/useIsClient"
import { AppLocale } from "src/localization/localization"
import { getLocaleOrThrow } from "src/localization/state"

export type NotFoundProps = {
  locale: AppLocale
}

export const NotFound = () => {
  const isClient = useIsClient()
  // not-found.tsx does not yet have access to route params.
  // https://github.com/vercel/next.js/discussions/43179
  // const locale = getLocaleOrThrow()
  const locale: AppLocale = isClient ? getLocaleOrThrow() : "de"
  const T = notFoundText[locale]

  return (
    <div className="flex flex-col gap-4 items-center justify-center min-h-[50vh]">
      <h1 className="text-h2-serif">{T.notFoundTitle}</h1>
      <p>{T.notFoundDescription}</p>
      <InteractiveLink href={routes.home}>{T.goHome}</InteractiveLink>
    </div>
  )
}
